import React from "react";
import ReactDOM from 'react-dom';
import Sidebar from "react-sidebar";
import { Link } from "gatsby"
import Logo from "../../images/orange.png"
import {IoMdArrowRoundBack} from "react-icons/io"
import firebase from './fire-base.js';


class ForgotPasswordComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      message: ""
    }
    this.displayMessage = React.createRef();
  }

  handleForgotPasswordInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  async log() {
    await firebase.analytics().logEvent('Forgot Password Email Sent', { name: 'Forgot Password'})
  }

  handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()

    var auth = firebase.auth()
    const { email } = this.state
    if(email.trim() !== "" && email.includes("@")) {
      auth.sendPasswordResetEmail(email).then(()=> {
        // Email sent.
        this.log()
      }).catch(function(error) {
         // An error happened.
      })

      this.setState({ message: "If a valid account with this email exists, we've sent you an email." })
      this.displayMessage.current.hidden = false
      setTimeout(()=>{ this.displayMessage.current.hidden = true }, 5000)
    }
  }

  render() {
    const { email } = this.state;
    return (
       <>
        <div className = "auth-header"><Link to="/signin"><IoMdArrowRoundBack className="back-arrow"/></Link><img src = {Logo} className = "auth-logo"/></div>
        <div className = "auth-form-wrapper">
          <form className = "auth-form col-sm-6 col-md-6 col-lg-4">
            <h4 className = "text-center">Forgot Password</h4>
            <br/>
            <div>
              <p className= "auth-text offset-md-1 col-md-10">{"Please enter your email and we'll send you instructions on how to reset your password."}</p>
            </div>
            <div className="form-group text-center">
              <input placeholder="Email" name="email" type="email" onChange={this.handleForgotPasswordInputChange} className = "auth-input"/>
            </div>
            <br/>
            <div className="text-center">
              <button className = "auth-btn col-md-10" onClick={this.handleSubmit}>Submit</button>
            </div>
            <p hidden className="offset-md-1 col-md-10" id="message" style={{textAlign: "center", color: "#4F8A10", backgroundColor: "#DFF2BF" }} ref={this.displayMessage}>{this.state.message}</p>
          </form>
        </div>
       </>
    )
  }
}

export default ForgotPasswordComponent;