import React from "react"
import 'bootstrap/dist/css/bootstrap.css'
import ForgotPassword from "../components/auth/forgot-password-component"

const ForgotPasswordPage = () => (

 <ForgotPassword></ForgotPassword>

)

export default ForgotPasswordPage